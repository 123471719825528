import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { MACD } from 'technicalindicators';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WavaxMacdIndicator = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [macdData, setMacdData] = useState(null);
  const [signal, setSignal] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      const now = new Date();
      const threeHoursAgo = new Date(now.getTime() - 3 * 60 * 60 * 1000);
      const filteredData = result.data
        .filter(entry => entry.token_name === 'WAVAX')
        .filter(entry => new Date(entry.timestamp) >= threeHoursAgo)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .reverse();

      setData(filteredData);

      if (filteredData.length > 26) {
        const prices = filteredData.map(entry => entry.price);
        const macdInput = {
          values: prices,
          fastPeriod: 50,
          slowPeriod: 80,
          signalPeriod: 14,
          SimpleMAOscillator: false,
          SimpleMASignal: false,
        };
        const calculatedMacd = MACD.calculate(macdInput);
        setMacdData(calculatedMacd);

        // Check for Buy/Sell Signal
        if (calculatedMacd.length >= 2) {
          const lastMacd = calculatedMacd[calculatedMacd.length - 1];
          const prevMacd = calculatedMacd[calculatedMacd.length - 2];

          if (prevMacd.MACD < prevMacd.signal && lastMacd.MACD > lastMacd.signal) {
            setSignal('BUY');
          } else if (prevMacd.MACD > prevMacd.signal && lastMacd.MACD < lastMacd.signal) {
            setSignal('SELL');
          } else {
            setSignal(null);
          }
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000); // Fetch data every 1 minute
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago'
    });
  };

  const labels = data.map(entry => formatDate(entry.timestamp));
  const macdValues = macdData || [];
  const recentMacd = macdValues.length > 0 ? macdValues[macdValues.length - 1] : null;

  const chartData = {
    labels: labels.slice(-macdValues.length),
    datasets: [
      {
        label: 'MACD Line',
        data: macdValues.map(d => d.MACD),
        borderColor: 'blue',
        fill: false,
        borderWidth: 2,
      },
      {
        label: 'Signal Line',
        data: macdValues.map(d => d.signal),
        borderColor: 'red',
        fill: false,
        borderWidth: 2,
      },
      {
        label: 'Histogram',
        data: macdValues.map(d => d.histogram),
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.3)',
        fill: true,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'MACD Value',
        },
      },
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        {recentMacd ? (
          <p>
            MACD Value: <strong>{recentMacd.MACD.toFixed(2)}</strong>, 
            Signal: <strong>{recentMacd.signal.toFixed(2)}</strong>, 
            Histogram: <strong>{recentMacd.histogram.toFixed(2)}</strong>
          </p>
        ) : (
          <p>No MACD data available.</p>
        )}
        {signal && (
          <p>
            <strong>{signal} Signal</strong>
          </p>
        )}
      </div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default WavaxMacdIndicator;
