import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { BollingerBands } from 'technicalindicators';
import { HStack, IconButton } from '@chakra-ui/react';
import { MdRefresh } from 'react-icons/md';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataWavaxBollinger = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bollingerData, setBollingerData] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
  
      // Set the time range to the last 6 hours
      const now = new Date();
      const sixHoursAgo = new Date(now.getTime() - 3 * 60 * 60 * 1000); // 6 hours ago
      const filteredData = result.data
        .filter(entry => entry.token_name === 'WAVAX')
        .filter(entry => new Date(entry.timestamp) >= sixHoursAgo) // Keep only last 6 hours
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort descending by timestamp
        .reverse(); // Reverse to make the oldest data on the left and newest on the right
  
      setData(filteredData);
  
      if (filteredData.length > 20) {
        const prices = filteredData.map(entry => entry.price);
        const bollingerInput = {
          period: 20,
          values: prices,
          stdDev: 2,
        };
        const calculatedBands = BollingerBands.calculate(bollingerInput);
        setBollingerData(calculatedBands);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  useEffect(() => {
    // Fetch data initially
    fetchData();
    // Set up interval to fetch data every 1 minute
    const intervalId = setInterval(fetchData, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleReload = () => {
    fetchData();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const labels = data.map(entry => formatDate(entry.timestamp));
  const wavaxPrices = data.map(entry => entry.price);

  // Display the most recent price (first entry after sorting)
  // Display the most recent price (last entry after reversing)
const mostRecentPrice = data.length > 0 ? data[data.length - 1].price : null;
const mostRecentTimestamp = data.length > 0 ? formatDate(data[data.length - 1].timestamp) : null;

  const upperBand = bollingerData ? bollingerData.map(b => b.upper) : [];
  const middleBand = bollingerData ? bollingerData.map(b => b.middle) : [];
  const lowerBand = bollingerData ? bollingerData.map(b => b.lower) : [];

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'WAVAX Price',
        data: wavaxPrices,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        fill: false,
        borderWidth: 2,
      },
      {
        label: 'Upper',
        data: upperBand,
        borderColor: 'blue',
        backgroundColor: 'lightblue',
        fill: false,
        borderDash: [5, 5],
        borderWidth: 2,
      },
     /** {
        label: 'Middle',
        data: middleBand,
        borderColor: 'green',
        backgroundColor: 'lightgreen',
        fill: false,
        borderDash: [5, 5],
        borderWidth: 2,
      }, */
      {
        label: 'Lower',
        data: lowerBand,
        borderColor: 'gold',
        fill: false,
        backgroundColor: 'yellow',
        borderDash: [5, 5],
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (USD)',
        },
      },
    },
  };

  const buySignal = mostRecentPrice && mostRecentPrice < lowerBand[lowerBand.length - 1];
  const sellSignal = mostRecentPrice && mostRecentPrice > upperBand[upperBand.length - 1];

  return (
    <div style={{ height: '400px', width: '100%' }}>
      {mostRecentPrice && (
        <div>
          <HStack>
            <h2>${mostRecentPrice.toFixed(2)} (as of {mostRecentTimestamp})</h2>
            <IconButton
              icon={<MdRefresh />}
              onClick={handleReload}
              aria-label="Reload Data"
              colorScheme="messenger"
              size="sm"
              variant="outline"
            />
          </HStack>
          {buySignal && <p style={{ color: 'green' }}>Buy Signal - Price below lower Bollinger Band</p>}
          {sellSignal && <p style={{ color: 'red' }}>Sell Signal - Price above upper Bollinger Band</p>}
          {!buySignal && !sellSignal && <p>No Buy or Sell Signals</p>}
        </div>
      )}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default DataWavaxBollinger;
