import { useState, useEffect } from "react";
import { Box, HStack, VStack, Text, Spacer, Spinner, Button } from "@chakra-ui/react";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";
import { formatAddress } from "../../utils/formatMetamask";
import FetchWavaxPriceNoReturn from "./FetchWavaxPriceNoReturn";
import AvaxDetailsModal2 from "./AvaxDetailsModal2";

const PaymasterInfoBox = ({ paymasterUSDBalance, paymasterAddress, paymasterBalance, loading, error }) => {
  // State to store the current AVAX price
  const [avaxPrice, setAvaxPrice] = useState(null);

  const minBalance = 0.2;
  
  // State to calculate and store the paymaster balance in USD
  const [paymasterBalanceUSD, setPaymasterBalanceUSD] = useState(null);

  // Handler function to be passed to FetchWavaxPriceNoReturn
  const handleAvaxPrice = (price) => {
    setAvaxPrice(price);
  };

  // Calculate the paymaster balance in USD whenever paymasterBalance or avaxPrice updates
  useEffect(() => {
    if (avaxPrice !== null && paymasterBalance !== null) {
      setPaymasterBalanceUSD((paymasterBalance * avaxPrice).toFixed(2));
    }
  }, [avaxPrice, paymasterBalance]);

  return (
    <>
      <Box p={1} bg="white" shadow="md" borderWidth="1px" borderRadius="md" w="100%">
      <HStack w="100%"  >
      <Box w={3} h={3} borderRadius="full" bg={paymasterBalance > minBalance ? "green.500" : "red.500"} />
          <Text  fontSize="12px">
            Spender: {formatAddress(paymasterAddress)} <CopyToClipboardButton value={paymasterAddress} />
          </Text>
          
          {paymasterBalance !== null && !loading && (
            <>
              <FetchWavaxPriceNoReturn onPriceFetched={handleAvaxPrice} />
              
              {paymasterBalance && paymasterBalanceUSD && (
                <Text fontSize="12px" >{paymasterBalance} AVAX (${paymasterBalanceUSD})</Text>
              )}
              <Spacer />
              <AvaxDetailsModal2 />
            </>
          )}
          
        
        
      </HStack>
        
    </Box>
    {/* Display low balance warning if the dot is red */}
    {paymasterBalance <= minBalance && (
      <Text color="red.500" mt={2} fontSize="sm">
        Warning: Spender balance is low.
      </Text>
    )}
    </>
  );
};

export default PaymasterInfoBox;
