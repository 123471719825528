import { IconButton, Tooltip } from "@chakra-ui/react";
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";

const ApprovalIconButton = ({
  owner1Approval,
  owner2Approval,
  owner3Approval,
  handleApproval,
}) => {
  const allApproved = owner1Approval && owner2Approval && owner3Approval;

  return (
    <>
    <Tooltip label="Approve Transfer" aria-label="Approve Transfer Tooltip">
    <IconButton
      colorScheme="blue"
      w="10px"
      size="sm"
      icon={allApproved ? <UnlockIcon /> : <LockIcon />}
      aria-label={allApproved ? "Unlocked" : "Locked"}
      onClick={handleApproval}
    />
    </Tooltip>
    </>
  );
};

export default ApprovalIconButton;
