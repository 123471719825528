import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Text, Box } from '@chakra-ui/react';

const DataTradeSettings = ({ onDataFetched }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/trade_settings/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result && result.data && result.data.length > 0) {
        const tradeSettings = result.data[0];
        setData(tradeSettings);

        // Call the callback function to pass data to parent
        if (onDataFetched) {
          onDataFetched(tradeSettings);
        }
      } else {
        throw new Error('No data found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!data) return <p>No data available</p>;

  const formatAsPercentage = (value) => `${(value * 100)}%`;
  const formatPercentage = (value) => `${value}%`;
  const formatDollar = (value) => `$${value}`;
  const formatAsMultiplier = (value) => `${value}x`;

  const parameterMapping = {
    starting_leverage: 'Starting Leverage', 
    leverage: 'Compound Leverage',               
    change_limit: 'Sell Limit',       
    loss_limit: 'Loss Limit',           
    collar_delta: 'Collar Delta',       
    compound_delta: 'Compound Delta',   
    min_usdc_balance: 'Min USDC Balance', 
    minStartingCollateral: 'Min Starting Collateral', 
    minCompound: 'Min Compound',             
    wavax_long_enabled: 'WAVAX Long',
    wavax_short_enabled: 'WAVAX Short',
    weth_long_enabled: 'WETH Long',
    weth_short_enabled: 'WETH Short',
    btc_long_enabled: 'BTC Long',
    btc_short_enabled: 'BTC Short',
    timestamp: 'Timestamp',
  };

  const enabledFields = [
    'wavax_long_enabled',
    'wavax_short_enabled',
    'weth_long_enabled',
    'weth_short_enabled',
    'btc_long_enabled',
    'btc_short_enabled',
  ];

  const formattedData = Object.keys(data)
    .filter(key => key !== 'id')
    .map(key => {
      let value = data[key];

      if (enabledFields.includes(key)) {
        value = value === 1 ? 'enabled' : 'disabled';
      }

      if (['collar_delta', 'compound_delta'].includes(key)) {
        value = formatAsPercentage(value);
      }

      if (['change_limit', 'loss_limit'].includes(key)) {
        value = formatPercentage(value);
      }

      if (['min_usdc_balance', 'minStartingCollateral', 'minCompound'].includes(key)) {
        value = formatDollar(value);
      }

      if (key === 'leverage' || key === 'starting_leverage') {
        value = formatAsMultiplier(value);
      }

      return [parameterMapping[key], value];
    });

  return (
    <Box maxWidth='360px'>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={0} gridColumnGap={1}>
        {formattedData.map(([parameter, value], index) => (
          <React.Fragment key={index}>
            <GridItem>
              <Text fontWeight="bold">
                {parameter}:
              </Text>
            </GridItem>
            <GridItem>
              <Text>{value}</Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default DataTradeSettings;
