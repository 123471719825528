import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { KST } from 'technicalindicators';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WavaxKSTIndicator = ({ onSignalChange }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [kstData, setKstData] = useState(null);
  const [signal, setSignal] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      // Filter to last 3 hours of WAVAX data
      const now = new Date();
      const threeHoursAgo = new Date(now.getTime() - 3 * 60 * 60 * 1000); // 3 hours ago
      const filteredData = result.data
        .filter(entry => entry.token_name === 'WAVAX')
        .filter(entry => new Date(entry.timestamp) >= threeHoursAgo)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .reverse();

      setData(filteredData);

      // Calculate KST
      if (filteredData.length > 50) { // Ensure sufficient data for KST calculation
        const prices = filteredData.map(entry => entry.price);

        const kstInput = {
          values: prices,
          ROCPer1: 10,
          ROCPer2: 15,
          ROCPer3: 20,
          ROCPer4: 30,
          SMAROCPer1: 10,
          SMAROCPer2: 10,
          SMAROCPer3: 10,
          SMAROCPer4: 15,
          signalPeriod: 9
        };

        const calculatedKST = KST.calculate(kstInput);
        setKstData(calculatedKST);

        // Generate BUY/SELL Signal
        const currentKST = calculatedKST[calculatedKST.length - 1];
        const previousKST = calculatedKST[calculatedKST.length - 2];

        let newSignal = 'HOLD';
        if (currentKST.kst > currentKST.signal && previousKST.kst <= previousKST.signal) {
          newSignal = 'BUY';
        } else if (currentKST.kst < currentKST.signal && previousKST.kst >= previousKST.signal) {
          newSignal = 'SELL';
        }

        if (newSignal !== signal) {
          setSignal(newSignal);
          if (onSignalChange) {
            onSignalChange(newSignal); // Call the callback function when signal changes
          }
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000); // Update every 1 minute
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago'
    });
  };

  const labels = data.map(entry => formatDate(entry.timestamp));
  const kstValues = kstData ? kstData.map(k => k.kst) : [];
  const signalValues = kstData ? kstData.map(k => k.signal) : [];

  // Chart Data
  const chartData = {
    labels: labels.slice(-kstValues.length),
    datasets: [
      {
        label: 'KST',
        data: kstValues,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
        borderWidth: 2,
      },
      {
        label: 'Signal Line',
        data: signalValues,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        fill: false,
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'KST Value',
        },
      },
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        {signal && (
          <p>
            Current Signal: <strong>{signal}</strong>
          </p>
        )}
      </div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default WavaxKSTIndicator;
