import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import DataCallerBalances from "../api/DataCallerBalances";
import DataExecutionFees from "../api/DataExecutionFees";
import DataCollateralUsed from "../api/DataCollateralUsed";
import DataTokenPriceBtc from "../api/DataTokenPriceBtc";
import DataTokenPriceWeth from "../api/DataTokenPriceWeth";
import DataTokenPriceWavax from "../api/DataTokenPriceWavax";
import DataLiquidityBtc from "../api/DataLiquidityBtc";
import DataLiquidityWeth from "../api/DataLiquidityWeth";
import DataLiquidityWavax from "../api/DataLiquidityWavax";
import DataNetwork from "../api/DataNetwork";
import DataRuntime from "../api/DataRuntime";
import DataBlockNumber from "../api/DataBlockNumber";
import DataIndicators from "../api/DataIndicators";
import DataRatio from "../api/DataRatio";
import DataStartingValue from "../api/DataStartingValue";
import WithdrawalRequests from "./gmx/events/WithdrawalRequest";
import ApprovalGranted from "./gmx/events/ApprovalGranted";
import RecentPositionIncreases from "./gmx/events/RecentPositionIncrease";
import TokenDeposits from "./gmx/events/TokenDeposits";
import TokenWithdrawalRequests from "./gmx/events/TokenWithdrawalRequests";
import DataWavaxBollinger from "../api/DataWavaxBollinger";
import WavaxRsiIndicator from "../api/WavaxRsiIndicator";

const DataTabs = () => {
  const [marketTabIndex, setMarketTabIndex] = useState(0);

  return (
    <Tabs variant="enclosed" p={0} w='100%'>
      <TabList>
        <Tab>Network</Tab>
        {/**<Tab>Expenditures</Tab> */}
        <Tab
          onClick={() => setMarketTabIndex(0)} // Reset to 'Prices' when Market is clicked
        >
          Market
        </Tab>
        <Tab>Events</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Network
            </Text>
            <Box bg="white" m={1} p={1}>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Current Runtime
              </Text>
              <DataRuntime />
            </Box>
            

            <Box bg="white" m={1} p={1}>
              <Text fontSize="md" fontWeight="bold" mb={2}>
                Gas Price
              </Text>
              <DataNetwork />
            </Box>

            <Box bg="white" m={1} p={1}>
              <Text fontSize="md" fontWeight="bold" mb={2}>
                Block
              </Text>
              <DataBlockNumber />
            </Box>
          
        </TabPanel>
        {/**<TabPanel>
          <Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Gas Balance
            </Text>
            <DataCallerBalances />
          </Box>

          <Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Execution Fees
            </Text>
            <DataExecutionFees />
          </Box>
          <Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Collateral Spending
            </Text>
            <DataCollateralUsed />
          </Box>

          <Box bg="white" m={1} p={1}>
          <Text fontSize="md" fontWeight="bold" mb={2}>
          Starting Position Value
          </Text>
          <DataStartingValue />
        </Box>

        
        </TabPanel> */}


        <TabPanel>
          <Tabs variant="soft-rounded" colorScheme="teal">
            <TabList>
              <Tab onClick={() => setMarketTabIndex(0)}>Prices</Tab>
              <Tab onClick={() => setMarketTabIndex(1)}>Indicators</Tab>
              <Tab onClick={() => setMarketTabIndex(2)}>Liquidity</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Prices
                </Text>
                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    WAVAX Price
                  </Text>
                  <DataTokenPriceWavax />
                </Box>
                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    WETH Price
                  </Text>
                  <DataTokenPriceWeth />
                </Box>
                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    BTC Price
                  </Text>
                  <DataTokenPriceBtc />
                </Box>
              </TabPanel>
              <TabPanel mb={12}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Indicators
                </Text>

                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Fear & Greed
                  </Text>
                  <DataIndicators />
                </Box>

                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Ratio
                  </Text>
                  <DataRatio />
                </Box>

                <Box bg="white" mb={4}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Bollinger Wavax
                  </Text>
                  <DataWavaxBollinger />
                </Box>

                <Box bg="white"  mt={12}>
                  <Text fontSize="md" fontWeight="bold" mb={2} >
                    RSI
                  </Text>
                  <WavaxRsiIndicator />
                </Box>
              </TabPanel>

              <TabPanel>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Liquidity
                </Text>
                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    WAVAX Liquidity
                  </Text>
                  <DataLiquidityWavax />
                </Box>
                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    WETH Liquidity
                  </Text>
                  <DataLiquidityWeth />
                </Box>
                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    BTC Liquidity
                  </Text>
                  <DataLiquidityBtc />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </TabPanel>
        <TabPanel>
            <Text>Events</Text>

            <Box p={2}>
            <WithdrawalRequests />
            </Box>

            <Box p={2}>
                <ApprovalGranted />
            </Box>

            <Box p={2}>
                <TokenDeposits />
            </Box>

            <Box p={2}>
                <TokenWithdrawalRequests />
            </Box>

            <Box p={2}>
                <RecentPositionIncreases / >
            </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default DataTabs;
