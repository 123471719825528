import {
    Box,
    Text,
    Heading,
    List,
    ListItem,
    ListIcon,
    VStack,
    Link,
    Button,
  } from "@chakra-ui/react";
  import { FaPlay, FaCheckCircle } from "react-icons/fa";
  
  function BlindfoldInfo() {
    return (
      <Box maxW="800px" mx="auto" py={10} px={6}>
        {/* Main Heading */}
        <Heading as="h1" size="xl" textAlign="center" mb={6}>
          Blindfold
        </Heading>
  
        {/* Overview */}
        <Text fontSize="lg" mb={4}>
          Blindfold is an advanced, automated cryptocurrency asset management
          application designed to optimize trading positions through dynamic,
          self-sustaining strategies. It capitalizes on market volatility by
          leveraging collateral from established exchange liquidity pools,
          enabling users to profit from both bullish and bearish market
          conditions.
        </Text>
        <Text fontSize="lg" mb={4}>
          Blindfold ensures efficient and cost-effective
          operations. This makes it a powerful tool for traders seeking to enhance
          profitability while minimizing risk in rapidly fluctuating market
          environments.
        </Text>
  
        {/* How It Works */}
        <Heading as="h2" size="md" mt={8} mb={4}>
          How It Works
        </Heading>
        <Text mb={4}>
          Blindfold monitors the exchange price for selected token assets and
          opens, increases, or decreases position sizes based on
          positive/negative price movements and market indicators.
        </Text>
  
        {/* Strategies */}
        <Heading as="h2" size="md" mt={8} mb={4}>
          Strategies
        </Heading>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={FaCheckCircle} color="teal.500" />
            <strong>Collar:</strong> Uses a leveraged ‘collar’ strategy by
            maintaining both a long and short position for each asset to reduce
            risk and capitalize on volatility.
          </ListItem>
          <ListItem>
            <ListIcon as={FaCheckCircle} color="teal.500" />
            <strong>DCA:</strong> Buys more of an asset at regular intervals or
            when the price falls, lowering the average entry price.
          </ListItem>
          <ListItem>
            <ListIcon as={FaCheckCircle} color="teal.500" />
            <strong>Momentum:</strong> Buys or sells based on the strength and
            direction of market trends.
          </ListItem>
          <ListItem>
            <ListIcon as={FaCheckCircle} color="teal.500" />
            <strong>Range:</strong> Buys near support levels and sells near
            resistance levels.
          </ListItem>
        </List>
  
        {/* Server */}
        <Heading as="h2" size="md" mt={8} mb={4}>
          Server
        </Heading>
        <Text mb={4}>
          The trade bot is a custom-built Node.js server application running on a
          cloud Ubuntu Linux VPS dedicated to running the trade bot.
        </Text>
  

      </Box>
    );
  }
  
  export default BlindfoldInfo;
  