import React from "react";
import {
  Box,
  SimpleGrid,
  HStack,
  VStack,
  Text,
  IconButton,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { RepeatIcon, ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";
import CollateralCard from "./CollateralCard";

const PositionsGrid = ({
  positionsDetails,
  collateralTokens,
  indexTokens,
  prices,
  tokenImages,
  wavax,
  usdc_e,
  weth,
  wbtc,
  showDetails,
  toggleDetails,
  formatAddress,
  sellLimit,
  _lossLimit,
  totalPositions,
  totalCollateral,
  utilizationRate,
  usdcBalance,
  handleReload,
}) => {
  return (
  
  
    /**<CollateralCard
    usdcBalance={usdcBalance}
    totalPositions={totalPositions}
    totalCollateral={totalCollateral}
    utilizationRate={utilizationRate}
    
    handleReload={handleReload}
  /> */
  
  
    <SimpleGrid
      columns={{ base: 2, sm: 2, md: 2 }} // Ensure 2 columns on mobile
      spacing={4} // Spacing between cards
    >
      {positionsDetails.map((position) => {
        const collateralTokenName =
          collateralTokens[position.positionIndex] === wavax
            ? "wavax"
            : collateralTokens[position.positionIndex] === usdc_e
            ? "usdc_e"
            : collateralTokens[position.positionIndex] === weth
            ? "weth"
            : collateralTokens[position.positionIndex] === wbtc
            ? "btc"
            : "unknown";

        const indexTokenName =
          indexTokens[position.positionIndex] === wavax
            ? "wavax"
            : indexTokens[position.positionIndex] === usdc_e
            ? "usdc_e"
            : indexTokens[position.positionIndex] === weth
            ? "weth"
            : indexTokens[position.positionIndex] === wbtc
            ? "btc"
            : "unknown";

        const indexTokenAddress =
          indexTokens[position.positionIndex] === wavax
            ? "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
            : indexTokens[position.positionIndex] === usdc_e
            ? "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664"
            : indexTokens[position.positionIndex] === weth
            ? "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
            : indexTokens[position.positionIndex] === wbtc
            ? "0x152b9d0FdC40C096757F570A51E494bd4b943E50"
            : "unknown";

        const tokenImage1 = tokenImages[collateralTokenName] || "./avax.png";
        const tokenImage2 = tokenImages[indexTokenName] || "./avax.png";

        const collateralTokenDisplayName =
          collateralTokenName === "usdc_e"
            ? "USDC.e"
            : collateralTokenName.toUpperCase();
        const indexTokenDisplayName =
          indexTokenName === "usdc_e" ? "USDC.e" : indexTokenName.toUpperCase();

        const positionType = position.isLong ? "Long" : "Short"; // Determine position type

        return (
            <>
        
          <VStack w="100%" justifyItems="center" key={position.positionIndex}>
            <Box
              w="100%"
              p={2}
              bg="white"
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
            >
              <Text>
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStyle: "italic",
                  }}
                >
                  ({positionType})
                </span>
              </Text>
              <HStack gap="2px" pl={1}>
                <img
                  src={tokenImage1}
                  alt={collateralTokenName}
                  style={{ width: "11px", marginRight: "0px" }}
                />
                <Text>/</Text>
                {/**
                  <Text fontSize="12px">{collateralTokenDisplayName} /</Text> */}
                <img
                  src={tokenImage2}
                  alt={indexTokenName}
                  style={{
                    width: "11px",
                    marginLeft: "0px",
                    marginRight: "1px",
                  }}
                />
                <Text fontSize="12px">{indexTokenDisplayName}</Text>

                <Text fontSize="12px" pl={1}>
                  ${prices[position.positionIndex] || "Loading..."}
                </Text>
              </HStack>
              <HStack gap="4px" pl={1}>
                <Text
                  fontSize="13px"
                  color={position.hasProfit === "1" ? "green.500" : "red.500"}
                >
                  (+/-):
                </Text>
                <Text
                  fontSize="13px"
                  textAlign="center"
                  color={position.hasProfit === "1" ? "green.500" : "red.500"}
                >
                  {position.deltaPercentage}
                </Text>
                <Text
                  
                  fontSize="13px"
                  textAlign="center"
                  color={position.hasProfit === "1" ? "green.500" : "red.500"}
                >
                  (${position.delta})
                </Text>
              </HStack>
              <Box fontSize="12px" pl={1}>
                <Text>Size: ${position.size}</Text>
                <Text>Collateral: ${position.collateral}</Text>
              </Box>

              {/* Toggle Details Button */}
              <HStack justify="center" mt={-1}>
                <IconButton
                  icon={showDetails ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={toggleDetails}
                  aria-label="Toggle Position Details"
                  size="sm"
                  
                  variant="unstyled"
                />
              </HStack>

              {/* Position Details (conditionally rendered) */}
              {showDetails && (
                <Box mt={1} pl={1} fontSize="12px">
                  <HStack>
                    <Text>{indexTokenDisplayName}</Text>
                    <Text>{formatAddress(indexTokenAddress)}</Text>
                    <CopyToClipboardButton value={indexTokenAddress} />
                  </HStack>
                  <Text>Leverage: {position.leverage}x</Text>
                  <Text>Entry Price: {position.entryPrice}</Text>
                  <Text>Sell Limit: {sellLimit} %</Text>
                  <Text>Buy Limit: {_lossLimit} %</Text>
                  <Text>Target Sell: ${position.sellPrice}</Text>
                  <Text>Target Buy: ${position.buyPrice}</Text>
                  <Text>Open/Close Fees: {position.openClose}</Text>
                  <Text>Trade Value: ${position.potential}</Text>
                  <Text mt={1}>Last: {position.lastIncreasedTime}</Text>
                </Box>
              )}
            </Box>
          </VStack>
          </>
        );
      })}
    </SimpleGrid>
    
  );
};

export default PositionsGrid;
