import React, { useState } from "react";
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { FiRepeat } from "react-icons/fi"; // Example swap/convert icon from react-icons
import SwapTokens from "./SwapTokens"; // Import your SwapTokens component

const SwapModal2 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="Swap" aria-label="Swap Tooltip" placement="top">
      <IconButton
        colorScheme="blue"
        size='md'
        fontSize='14px'
        variant='unstyled'
        onClick={onOpen}
        icon={<FiRepeat />} // Swap or convert icon
        aria-label="Open Swap Modal" // Accessibility label
      />
      </Tooltip>

      {/* Modal for SwapTokens component */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Swap Tokens</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={2} mb={2} mt={-2}>
            <SwapTokens /> {/* SwapTokens component loaded inside the modal */}
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default SwapModal2;
