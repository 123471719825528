import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataNetwork = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/network_data/';

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Get the most recent entry
  const mostRecent = data.length > 0 ? data[data.length - 1] : null;

  const labels = data.map(entry => formatDate(entry.timestamp));
  const gasPriceData = data.map(entry => entry.gas_price);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Gas Price',
        data: gasPriceData,
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Gas Price',
        },
      },
    },
  };

  return (
    <div>
      {/* Display the most recent gas price and timestamp above the chart */}
      {mostRecent && (
        <div style={{ marginBottom: '20px' }}>
          <p><strong>Price:</strong> {(mostRecent.gas_price).toFixed(0)}</p>
          <p><strong>Timestamp:</strong> {formatDate(mostRecent.timestamp)}</p>
        </div>
      )}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default DataNetwork;
