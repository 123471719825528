import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { HStack, Text } from "@chakra-ui/react";
import AvaxCoinGeckoPrice from "../components/coinGecko/AvaxCoinGeckoPrice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataCallerBalances = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Update the API URL to fetch from caller_balances
  const apiUrl = "https://rebekah.cloud/api/caller_balance/";

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const labels = [...new Set(data.map((entry) => formatDate(entry.timestamp)))];

  const avaxEntries = data.filter((entry) => entry.token === "AVAX");
  const wethEntries = data.filter((entry) => entry.token === "WETH");
  const btcEntries = data.filter((entry) => entry.token === "BTC");
  const usdcEntries = data.filter((entry) => entry.token === "USDC");

  const avaxData = avaxEntries.map((entry) => entry.balance);
  const wethData = wethEntries.map((entry) => entry.balance);
  const btcData = btcEntries.map((entry) => entry.balance);
  const usdcData = usdcEntries.map((entry) => entry.balance);

  const mostRecentAvaxEntry = avaxEntries[avaxEntries.length - 1] || null;
  const mostRecentWethEntry = wethEntries[wethEntries.length - 1] || null;
  const mostRecentBtcEntry = btcEntries[btcEntries.length - 1] || null;
  const mostRecentUsdcEntry = usdcEntries[usdcEntries.length - 1] || null;

  const mostRecentAvaxBalance = mostRecentAvaxEntry
    ? mostRecentAvaxEntry.balance.toFixed(2)
    : "N/A";
  const mostRecentWethBalance = mostRecentWethEntry
    ? mostRecentWethEntry.balance.toFixed(2)
    : "N/A";
  const mostRecentBtcBalance = mostRecentBtcEntry
    ? mostRecentBtcEntry.balance.toFixed(2)
    : "N/A";
  const mostRecentUsdcBalance = mostRecentUsdcEntry
    ? mostRecentUsdcEntry.balance.toFixed(2)
    : "N/A";

  const mostRecentAvaxTimestamp = mostRecentAvaxEntry
    ? formatDate(mostRecentAvaxEntry.timestamp)
    : "N/A";
  const mostRecentWethTimestamp = mostRecentWethEntry
    ? formatDate(mostRecentWethEntry.timestamp)
    : "N/A";
  const mostRecentBtcTimestamp = mostRecentBtcEntry
    ? formatDate(mostRecentBtcEntry.timestamp)
    : "N/A";
  const mostRecentUsdcTimestamp = mostRecentUsdcEntry
    ? formatDate(mostRecentUsdcEntry.timestamp)
    : "N/A";

  const chartOptions = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value.toFixed(2);
          },
        },
      },
    },
  };

  const createChartData = (tokenData, tokenLabel, color) => ({
    labels: labels,
    datasets: [
      {
        label: tokenLabel,
        data: tokenData,
        fill: false,
        borderColor: color,
        tension: 0.1,
      },
    ],
  });

  return (
    <div>
      
      <HStack>
      <p>
        {mostRecentAvaxBalance} AVAX
      </p>
      <AvaxCoinGeckoPrice amount={mostRecentAvaxBalance}/>
      </HStack>
      {mostRecentAvaxTimestamp && (
            <Text fontSize="sm" color="gray.500">
              Last Update: {mostRecentAvaxTimestamp}
            </Text>
      )
      }
      <Line
        data={createChartData(avaxData, "AVAX", "red")}
        options={chartOptions}
      />

    </div>
  );
};

export default DataCallerBalances;
