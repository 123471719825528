import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataTokenPriceBtc = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data.filter(entry => entry.token_name === 'BTC')); // Filter for BTC
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const labels = data.map(entry => formatDate(entry.timestamp));
  const btcPrices = data.map(entry => entry.price);

  // Get the most recent price entry
  const mostRecentPrice = data.length > 0 ? data[data.length - 1].price : null;
  const mostRecentTimestamp = data.length > 0 ? formatDate(data[data.length - 1].timestamp) : null;

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Price',
        data: btcPrices,
        borderColor: 'orange',
        backgroundColor: 'rgba(255, 255, 0, 0.2)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (USD)',
        },
      },
    },
  };

  return (
    <div>
      {mostRecentPrice && (
        <div>
          <h2>${mostRecentPrice.toFixed(2)} (as of {mostRecentTimestamp})</h2>
        </div>
      )}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default DataTokenPriceBtc;
