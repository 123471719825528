import React, { useState, useEffect } from 'react';
import { Grid, GridItem, IconButton, Tooltip, useToast, Text, Box } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons'; // Importing the copy icon from Chakra UI

const DataAccountData = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast(); // Hook for using Chakra UI toast

  const apiUrl = 'https://rebekah.cloud/api/account_data/';

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data[0]); // Assuming only one account data object is returned
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Function to format account and tenet addresses as hyperlinks to Snowtrace.io
const formatAddress = (address) => {
  const formattedAddress = `${address.slice(0, 4)}...${address.slice(-4)}`;
  return (
    <a 
      href={`https://snowtrace.io/address/${address}`} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ textDecoration: 'underline', color: 'blue' }}
    >
      {formattedAddress}
    </a>
  );
};




  // Function to copy unformatted address to clipboard and show toast notification
  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address).then(() => {
      toast({
        title: "Copied to clipboard",
        description: `Address ${address} copied successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }, () => {
      toast({
        title: "Failed to copy",
        description: `Unable to copy ${address}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  // Map parameter names to friendly labels and format the account/tenet addresses
  const parameterMapping = {
    account_address: 'Operator',
    tenet: 'Tenet',
    provider_url: 'Provider',
    network: 'Network',
    network_chain_id: 'ChainId',
    timestamp: 'Timestamp'
  };

  // Format the data with renaming and address formatting
  const formattedData = Object.keys(data)
    .filter(key => key !== 'id') // Remove the id key
    .map(key => {
      const label = parameterMapping[key] || key;
      let value = data[key];

      // Format the account and tenet addresses
      if (key === 'account_address' || key === 'tenet') {
        return (
          <React.Fragment key={key}>
            <GridItem>
              <Text fontWeight="bold" mb={1}>
                {label}:
              </Text>
            </GridItem>
            <GridItem>
              <Text>
                {formatAddress(value)}
                <Tooltip label="Copy full address" aria-label="Copy full address">
                  <IconButton
                    icon={<CopyIcon />}
                    size="xs"
                    ml={2}
                    onClick={() => copyToClipboard(value)}
                    aria-label={`Copy ${label}`}
                  />
                </Tooltip>
              </Text>
            </GridItem>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={key}>
          <GridItem>
            <Text fontWeight="bold" mb={1}>
              {label}:
            </Text>
          </GridItem>
          <GridItem>
            <Text>{value}</Text>
          </GridItem>
        </React.Fragment>
      );
    });

  return (
    <Box maxWidth='360px'>
       <Grid 
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} 
        gap={0} gridColumnGap={1}
      >
        {formattedData}
      </Grid>
    </Box>
  );
};

export default DataAccountData;
