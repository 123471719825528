import { Box, Text } from "@chakra-ui/react";

const WithdrawalStatusNotification = ({
  owner1Approval,
  owner2Approval,
  owner3Approval,
}) => {
  const isFullyApproved = owner1Approval && owner2Approval && owner3Approval;

  let statusMessage;
  let ownersApproved = [];

  if (owner1Approval) ownersApproved.push("Owner 1");
  if (owner2Approval) ownersApproved.push("Owner 2");
  if (owner3Approval) ownersApproved.push("Owner 3");

  if (isFullyApproved) {
    statusMessage = (
      <Text bg="yellow" w="100%" color="red" textAlign="center">
        Withdrawals: ENABLED
      </Text>
    );
  } else if (ownersApproved.length > 0) {
    statusMessage = (
      <>
        <Text bg="Highlight" w="100%" color="HighlightText" textAlign="center">
          Withdrawals: DISABLED
        </Text>
        <Text p={2} bg="white" textAlign="center">
          {ownersApproved.join(" & ")} {ownersApproved.length > 1 ? "have" : "has"} enabled token withdrawals
        </Text>
      </>
    );
  } else {
    statusMessage = (
      <Text w="100%" textAlign="center">
        Withdrawals: DISABLED
      </Text>
    );
  }

  return <Box w="100%" p={2} bg="ghostwhite">{statusMessage}</Box>;
};

export default WithdrawalStatusNotification;
