import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataLiquidityWavax = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/liquidity_data/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Extract the most recent entry
  const mostRecentEntry = data.length > 0 ? data[data.length - 1] : null;
  const mostRecentLongLiquidity = mostRecentEntry ? mostRecentEntry.wavax_long_liquidity : "N/A";
  const mostRecentShortLiquidity = mostRecentEntry ? mostRecentEntry.wavax_short_liquidity : "N/A";
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : "N/A";

  // Prepare data for the chart
  const labels = data.map(entry => formatDate(entry.timestamp));
  const wavaxLongData = data.map(entry => entry.wavax_long_liquidity);
  const wavaxShortData = data.map(entry => entry.wavax_short_liquidity);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Long Liquidity',
        data: wavaxLongData,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        fill: true,
      },
      {
        label: 'Short Liquidity',
        data: wavaxShortData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Liquidity (USD)',
        },
      },
    },
  };

  return (
    <div>
      {mostRecentEntry && (
        <div style={{ marginBottom: '20px' }}>
          <p>Long Liquidity: ${mostRecentLongLiquidity.toFixed(2)}</p>
          <p>Short Liquidity: ${mostRecentShortLiquidity.toFixed(2)}</p>
          <p>Timestamp: {mostRecentTimestamp}</p>
        </div>
      )}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default DataLiquidityWavax;
