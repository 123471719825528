import React, { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";
import {
  Box,
  Grid,
  GridItem,
  Link,
  Text,
  VStack,
  useToast,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import PositionRouter from "../contracts/PositionRouter.json";
import TenetWallet from "../../../contracts/TenetWallet.json";
import { formatAddress } from "../../../utils/formatMetamask";

const RecentPositionIncreases = () => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://api.avax.network/ext/bc/C/rpc"
  );
  const accountAddress = TenetWallet.address;
  const [events, setEvents] = useState([]);
  const toast = useToast();

  const fetchRecentEvents = useCallback(async () => {
    const contract = new ethers.Contract(
      PositionRouter.address,
      PositionRouter.abi,
      provider
    );

    try {
      const eventFilter = contract.filters.CreateIncreasePosition(
        accountAddress
      );
      const events = await contract.queryFilter(eventFilter, -1000, "latest");
      const formattedEvents = events.map((event) => {
        const {
          account,
          path,
          indexToken,
          amountIn,
          minOut,
          sizeDelta,
          isLong,
          acceptablePrice,
          executionFee,
        } = event.args;
        return {
          account,
          path,
          indexToken,
          amountIn: amountIn.toString(),
          minOut: ethers.utils.formatUnits(minOut, 18),
          sizeDelta: sizeDelta.toString(),
          isLong,
          acceptablePrice: ethers.utils.formatUnits(acceptablePrice, 18),
          executionFee: ethers.utils.formatUnits(executionFee, 18),
          blockNumber: event.blockNumber,
          transactionHash: event.transactionHash,
        };
      });
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      toast({
        title: "Error",
        description: "Could not fetch events. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [accountAddress, provider, toast]);

  useEffect(() => {
    fetchRecentEvents();
  }, [fetchRecentEvents]);

  // Convert position size back to USD
  function parseSize(positionSize, decimals = 0) {
    // Ensure the input is a BigNumber
    if (!ethers.BigNumber.isBigNumber(positionSize)) {
      throw new Error("Invalid input: positionSize must be a BigNumber.");
    }

    // Convert the BigNumber to a string and divide by 10^30 to get the base USD value
    const baseValue = positionSize
      .div(ethers.BigNumber.from("10").pow(30))
      .toString();

    // Adjust for the specified decimals
    // For example, if baseValue is 12345 and decimals is 2, this results in 123.45
    const decimalValue = parseFloat(baseValue) / Math.pow(10, decimals);

    return decimalValue.toFixed(decimals);
  }

  return (
    <VStack spacing={4} align="start" w="auto" p={2}>
      <HStack justifyContent="space-between" width="100%">
        <Text as='b' color="blue.500">
          Most Recent Increase Orders
        </Text>
        <IconButton
          icon={<RepeatIcon />}
          aria-label="Reload Increase Orders"
          onClick={fetchRecentEvents}
          colorScheme="blue"
          variant={'outline'}
        />
      </HStack>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={6}
        w="full"
        p={2}
        borderWidth="1px"
        borderRadius="lg"
      >
        <GridItem>
          <Text fontWeight="bold">Index Token</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Is Long</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Block Number</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">TX</Text>
        </GridItem>
        {events.map((event, index) => (
          <React.Fragment key={index}>
            <GridItem>{formatAddress(event.indexToken)}</GridItem>
            <GridItem>{event.isLong ? "Yes" : "No"}</GridItem>
            <GridItem>{event.blockNumber}</GridItem>
            <GridItem overflow={"hidden"}>
              <Link
                target="_blank"
                href={`https://snowtrace.io/tx/${event.transactionHash}`}
              >
                View Transaction
              </Link>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </VStack>
  );
};

export default RecentPositionIncreases;
