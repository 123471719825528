import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import TenetWallet from "../../../contracts/TenetWallet.json"; // Your contract's ABI and address

const TokenDeposits = () => {
  const [tokenDepositEvents, setTokenDepositEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://api.avax.network/ext/bc/C/rpc" // Replace with your actual RPC URL
    );

    const fetchTokenDepositEvents = async () => {
      setLoading(true);
      try {
        const contract = new ethers.Contract(TenetWallet.address, TenetWallet.abi, provider);

        // Filter to get all past TokenDeposit events
        const filter = contract.filters.TokenDeposit();
        const events = await contract.queryFilter(filter, -1000, "latest");

        // Format the events and store in state
        const formattedEvents = events.map((event) => {
          return {
            token: event.args[0], // Token address
            amount: ethers.utils.formatUnits(event.args[1], 18), // Amount in token units (assuming the token has 18 decimals)
            timestamp: new Date(event.blockNumber * 1000).toLocaleString(), // Timestamp from block number
          };
        });

        setTokenDepositEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching token deposit events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenDepositEvents();
  }, []); // Empty dependency array to run only once

  return (
    <div>
      <h2>Token Deposit Events</h2>
      {loading ? (
        <p>Loading token deposit events...</p>
      ) : tokenDepositEvents.length === 0 ? (
        <p>No token deposit events found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Token</th>
              <th>Amount</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {tokenDepositEvents.map((event, index) => (
              <tr key={index}>
                <td>{event.token}</td>
                <td>{event.amount}</td>
                <td>{event.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TokenDeposits;
