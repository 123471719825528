import React from 'react';
import { Box, Heading, Text, Link, List, ListItem, Collapse, Button, VStack } from '@chakra-ui/react';

const BlindfoldWalletContract = () => {
  const [showOverview, setShowOverview] = React.useState(false);
  const [showFeatures, setShowFeatures] = React.useState(false);
  const [showFunctions, setShowFunctions] = React.useState(false);
  const [showUpgrades, setShowUpgrades] = React.useState(false);

  return (
    <Box p={6}>
      <Heading as="h2" size="lg" mb={4}>
        Blindfold Wallet v1 SmartContract
      </Heading>

      <Text fontSize="md" mb={2}>
        GitHub Repository: 
        <Link href="https://github.com/rob4102/gmx-contract" isExternal color="teal.500">
          Blindfold Wallet v1 Contract
        </Link> 
        (Note: Contract in repo may not be up to date)
      </Text>

      <VStack spacing={4} align="start">
        <Box>
          <Button size="sm" onClick={() => setShowOverview(!showOverview)} colorScheme="teal">
            Overview
          </Button>
          <Collapse in={showOverview} animateOpacity>
            <Text mt={2} noOfLines={4} maxWidth={300}>
              Blindfold Wallet v1 is a Solidity smart contract designed to manage a multi-owner wallet with DeFi protocol integration. It supports multiple token types, dual ownership, and plugin approval for secure interaction with DeFi contracts.
            </Text>
          </Collapse>
        </Box>

        <Box>
          <Button size="sm" onClick={() => setShowFeatures(!showFeatures)} colorScheme="teal">
            Features
          </Button>
          <Collapse in={showFeatures} animateOpacity>
            <List mt={2} spacing={2}>
              <ListItem>Dual Ownership: Managed by three owners for added security.</ListItem>
              <ListItem>Multi-Token Support: Supports AVAX, USDC, WAVAX, WETH, and BTC.</ListItem>
              <ListItem>DeFi Integration: Interacts with GMX contracts like OrderBook and PositionRouter.</ListItem>
              <ListItem>Event Emissions: Logs all major actions for transparency.</ListItem>
            </List>
          </Collapse>
        </Box>

        <Box>
          <Button size="sm" onClick={() => setShowFunctions(!showFunctions)} colorScheme="teal">
            Contract Functions
          </Button>
          <Collapse in={showFunctions} animateOpacity>
            <List mt={2} spacing={2}>
              <ListItem>
                <strong>Constructor:</strong> Initializes the contract with three owners and a paymaster.
              </ListItem>
              <ListItem>
                <strong>Deposit Functions:</strong> Allows depositing of native AVAX and ERC-20 tokens.
              </ListItem>
              <ListItem>
                <strong>Withdrawal Functions:</strong> Request and execute AVAX and token withdrawals.
              </ListItem>
              <ListItem>
                <strong>Position Management:</strong> Manage trading positions with GMX contracts.
              </ListItem>
              <ListItem>
                <strong>Token Swap:</strong> Swap tokens through the Router contract.
              </ListItem>
              <ListItem>
                <strong>Utility Functions:</strong> Approve plugins, get balances, and verify approvals.
              </ListItem>
            </List>
          </Collapse>
        </Box>

        <Box>
          <Button size="sm" onClick={() => setShowUpgrades(!showUpgrades)} colorScheme="teal">
            Contract Upgrades (10/14/2024)
          </Button>
          <Collapse in={showUpgrades} animateOpacity>
            <List mt={2} spacing={2}>
              <ListItem>
                <strong>Swap Tokens to AVAX:</strong> Convert ERC20 tokens into native AVAX.
              </ListItem>
              <ListItem>
                <strong>Transfer AVAX to Paymaster:</strong> Automatically replenish paymaster AVAX balance.
              </ListItem>
              <ListItem>
                <strong>Set Transfer Cap:</strong> Control the maximum AVAX transfer per transaction.
              </ListItem>
            </List>
          </Collapse>
        </Box>
      </VStack>
    </Box>
  );
};

export default BlindfoldWalletContract;
