import { Box, Flex, VStack, Stack, Image } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const NavBar = ({ loading }) => {
  return (
    <Box px={4} mb={2}>
      <Flex h={12} p={2} alignItems="center" justifyContent="space-between">
        <Box>
          <VStack gap="auto" mt={2}>
            <Image
              src="/logo10.png"
              alt="Logo"
              maxW={{ base: "80px", md: "100px", lg: "120px" }} // Adjust max-width for different screen sizes
              h="auto" // Maintain aspect ratio
              objectFit="contain"
            />
          </VStack>
        </Box>

        <Flex alignItems="center">
          <Stack direction="row" spacing={2} mt={1}>
            <ConnectButton
              label="Connect"
              accountStatus="avatar"
              chainStatus="icon"
              showBalance={{
                smallScreen: false,
                largeScreen: true,
              }}
            />
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
