import { Heading, Link, Text } from "@chakra-ui/react";
import { formatAddress } from "../../utils/formatMetamask";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";



const ContractInfo = ({ contractName, activeContractAddress, approvalWindowStatus }) => {
  return (
    <>
      <Heading size="sm" textAlign="center" color='silver'>
        {contractName}
      </Heading>
      <Heading fontSize="xs" mb={2} borderRadius={6} color='silver' textAlign={"center"}>
        <Link
          target="_blank"
          href={`https://snowtrace.io/address/${activeContractAddress}/`}
        >
          {formatAddress(activeContractAddress)}
        </Link>
        <CopyToClipboardButton value={activeContractAddress} />
      </Heading>
      <Text>{approvalWindowStatus}</Text>
    </>
  );
};

export default ContractInfo;
