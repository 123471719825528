import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";

function ApplicationTopology() {
  return (
    <Box maxW="800px" mx="auto" py={10} px={6}>
      {/* Title */}
      <Heading as="h1" size="xl" textAlign="center" mb={6}>
        Application Topology
      </Heading>

      {/* Image */}
      <Image 
        src="applicationTopology.png" // Update this path to where the image is stored
        alt="Application Topology Diagram" 
        borderRadius="md"
        w='250px'
        mb={8}
      />

      {/* Description */}
      <VStack spacing={6} align="start">
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Backend - VPS Ubuntu Linux Server (Chicago)
          </Heading>
          <Text>
            The backend server is hosted on a VPS Ubuntu Linux server located in Chicago. This server runs core processes like the Trade Bot, interacting with blockchain networks and the Jakub Contract for automated cryptocurrency asset management.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            Frontend - Web Interface (Germany)
          </Heading>
          <Text>
            The frontend web interface is hosted in Germany and provides a user dashboard. It connects to the backend services to display real-time trading information and enable users to interact with the trading bot settings and data.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            API Server - VPS Ubuntu Linux Server
          </Heading>
          <Text>
            The API server is another VPS Ubuntu Linux server that handles requests and processes data exchange between the frontend and backend services. It plays a crucial role in routing data securely and efficiently.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            Storage - Database Cloud Storage Bucket
          </Heading>
          <Text>
            Cloud storage is used to securely store trading data and logs. The storage bucket enables quick access and backup capabilities, ensuring data persistence and integrity.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default ApplicationTopology;
