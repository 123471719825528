import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import {
  injectedWallet,
  braveWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  trustWallet,
  uniswapWallet,
  phantomWallet,
} from "@rainbow-me/rainbowkit/wallets";
import "@rainbow-me/rainbowkit/styles.css";
import {
  connectorsForWallets,
  darkTheme,
  lightTheme,
  midnightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import theme from './theme';
import { coreWallet } from "@rainbow-me/rainbowkit/wallets";
import {MdOutlineAccountBalanceWallet} from 'react-icons/md'
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import App from "./App";

const projectId = "f95a49f83bc1ded0c992842132cbb0a3";

const dappName = "TENET";

const avalanche = {
  id: 43114,
  name: 'Avalanche',
  network: 'avalanche',
  iconUrl: 'https://openseauserdata.com/files/7918d0c895fd465b9e4339450d83a4f9.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Avalanche',
    symbol: 'AVAX',
  },
  rpcUrls: {
    public: { http: ['https://api.avax.network/ext/bc/C/rpc'] },
    default: { http: ['https://api.avax.network/ext/bc/C/rpc'] },
  },
  blockExplorers: {
    default: { name: 'SnowTrace', url: 'https://snowtrace.io' },
    etherscan: { name: 'SnowTrace', url: 'https://snowtrace.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11'    },
  },
  testnet: false,
};

const avalancheFujiTestnet = {
  id: 43113,
  name: 'Avalanche Fuji Testnet',
  network: 'fuji',
  iconUrl: 'https://openseauserdata.com/files/7918d0c895fd465b9e4339450d83a4f9.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Avalanche',
    symbol: 'AVAX',
  },
  rpcUrls: {
    public: { http: ['https://api.avax-test.network/ext/bc/C/rpc'] },
    default: { http: ['https://api.avax-test.network/ext/bc/C/rpc'] },
  },
  blockExplorers: {
    default: { name: 'SnowTrace', url: 'https://testnet.snowtrace.io' },
    etherscan: { name: 'SnowTrace', url: 'https://testnet.snowtrace.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11'    },
  },
  testnet: true,
};

const CustomAvatar = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img
      src={ensImage}
      alt={`Avatar for ${address}`}
      width={size}
      height={size}
      style={{ borderRadius: "50%" }}
    />
  ) : (
   <MdOutlineAccountBalanceWallet color="black"/>
  );
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    avalanche,
    avalancheFujiTestnet, // Add the Fuji Testnet here
  ],
  [publicProvider()],
);

const connectors = connectorsForWallets([
  {
    groupName: "Popular",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      coreWallet({ projectId, chains }),
      braveWallet({ chains }),
      coinbaseWallet({ dappName, chains }),
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      uniswapWallet({ projectId, chains }),
      phantomWallet({ chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

// Use ReactDOM.render if you're not on React 18+
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        coolMode
        avatar={CustomAvatar}
        showRecentTransactions={true}
        chains={chains}
        theme={lightTheme({
          accentColor: 'black',
          accentColorForeground: 'white',
          borderRadius: 'large',
          fontStack: 'system',
          overlayBlur: 'small',
        })}
      >
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
);

reportWebVitals();
