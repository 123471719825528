import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { RSI } from 'technicalindicators';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WavaxRsiIndicator = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rsiData, setRsiData] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      const now = new Date();
      const threeHoursAgo = new Date(now.getTime() - 3 * 60 * 60 * 1000); // 3 hours ago
      const filteredData = result.data
        .filter(entry => entry.token_name === 'WAVAX')
        .filter(entry => new Date(entry.timestamp) >= threeHoursAgo) // Keep only last 3 hours
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort descending by timestamp
        .reverse(); // Reverse to make the oldest data on the left and newest on the right

      setData(filteredData);
      
      // Calculate RSI
      if (filteredData.length > 14) { // Ensure at least 14 prices for RSI calculation
        const prices = filteredData.map(entry => entry.price);
        const rsiInput = {
          values: prices, // Oldest to newest for RSI calculation
          period: 14,
        };
        const calculatedRsi = RSI.calculate(rsiInput);
        setRsiData(calculatedRsi);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();
    // Set up interval to fetch data every 1 minute
    const intervalId = setInterval(fetchData, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago' // Optional: convert to CST
    });
  };

  const labels = data.map(entry => formatDate(entry.timestamp));
  const rsiValues = rsiData || [];

  // Get the most recent RSI value and timestamp
  const recentRsiValue = rsiValues.length > 0 ? rsiValues[rsiValues.length - 1] : null;
  const recentTimestamp = data.length > 0 ? formatDate(data[data.length - 1].timestamp) : null;

  // Chart Data
  const chartData = {
    labels: labels.slice(-rsiValues.length), // Match labels with RSI data
    datasets: [
      {
        label: 'RSI',
        data: rsiValues,
        borderColor: 'purple',
        backgroundColor: 'rgba(128, 0, 128, 0.2)',
        fill: true,
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'RSI Value',
        },
        min: 0,
        max: 100,
      },
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        {recentRsiValue !== null && recentTimestamp !== null ? (
          <p>
            AVAX RSI Value: <strong>{recentRsiValue.toFixed(2)}</strong> at {recentTimestamp}
          </p>
        ) : (
          <p>No RSI data available.</p>
        )}
      </div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default WavaxRsiIndicator;
