import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { WilliamsR } from 'technicalindicators';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WavaxWilliamsRIndicator = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [williamsRData, setWilliamsRData] = useState(null);
  const [signal, setSignal] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';
  const williamsRPeriod = 14; // Typical period for Williams %R is 14

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      // Filter to last 3 hours of WAVAX data
      const now = new Date();
      const threeHoursAgo = new Date(now.getTime() - 6 * 60 * 60 * 1000); // 3 hours ago
      const filteredData = result.data
        .filter(entry => entry.token_name === 'WAVAX')
        .filter(entry => new Date(entry.timestamp) >= threeHoursAgo)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .reverse();

      setData(filteredData);

      // Calculate Williams %R
      if (filteredData.length >= williamsRPeriod) {
        const highPrices = filteredData.map(entry => entry.high);
        const lowPrices = filteredData.map(entry => entry.low);
        const closePrices = filteredData.map(entry => entry.price);
        const williamsRInput = {
          high: highPrices,
          low: lowPrices,
          close: closePrices,
          period: williamsRPeriod,
        };
        const calculatedWilliamsR = WilliamsR.calculate(williamsRInput);
        setWilliamsRData(calculatedWilliamsR);

        // Generate BUY/SELL Signal
        const currentWilliamsR = calculatedWilliamsR[calculatedWilliamsR.length - 1];

        if (currentWilliamsR < -80) {
          setSignal('BUY'); // Oversold
        } else if (currentWilliamsR > -20) {
          setSignal('SELL'); // Overbought
        } else {
          setSignal('HOLD');
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000); // Update every 1 minute
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago'
    });
  };

  const labels = data.map(entry => formatDate(entry.timestamp));
  const williamsRValues = williamsRData || [];

  // Get the most recent Williams %R value and timestamp
  const recentWilliamsRValue = williamsRValues.length > 0 ? williamsRValues[williamsRValues.length - 1] : null;
  const recentTimestamp = data.length > 0 ? formatDate(data[data.length - 1].timestamp) : null;

  // Chart Data
  const chartData = {
    labels: labels.slice(-williamsRValues.length),
    datasets: [
      {
        label: 'Williams %R',
        data: williamsRValues,
        borderColor: 'orange',
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        fill: true,
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Williams %R Value',
        },
        min: -100,
        max: 0,
      },
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        {recentWilliamsRValue !== null && recentTimestamp !== null ? (
          <p>
            WAVAX Williams %R Value: <strong>{recentWilliamsRValue.toFixed(2)}</strong> at {recentTimestamp}
          </p>
        ) : (
          <p>No Williams %R data available.</p>
        )}
        {signal && (
          <p>
            Current Signal: <strong>{signal}</strong>
          </p>
        )}
      </div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default WavaxWilliamsRIndicator;
