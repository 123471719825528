import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import TenetWallet from "../../../contracts/TenetWallet.json"; // Your contract's ABI and address

const TokenWithdrawalRequests = () => {
  const [tokenWithdrawalEvents, setTokenWithdrawalEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://api.avax.network/ext/bc/C/rpc" // Replace with your actual RPC URL
    );

    const fetchTokenWithdrawalEvents = async () => {
      setLoading(true);
      try {
        const contract = new ethers.Contract(TenetWallet.address, TenetWallet.abi, provider);

        const filter = contract.filters.TokenWithdrawalRequested();
          const events = await contract.queryFilter(filter, -1000, "latest");
        

        // Format the events and store in state
        const formattedEvents = events.map((event) => {
          return {
            token: event.args[0], // Token address
            amount: ethers.utils.formatUnits(event.args[1], 18), // Amount in token units (assuming 18 decimals)
            timestamp: new Date(event.args[2].toNumber() * 1000).toLocaleString(), // Convert timestamp to date
          };
        });

        setTokenWithdrawalEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching token withdrawal events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenWithdrawalEvents();
  }, []); // Empty dependency array to run only once

  return (
    <div>
      <h2>Token Withdrawal Requests</h2>
      {loading ? (
        <p>Loading token withdrawal requests...</p>
      ) : tokenWithdrawalEvents.length === 0 ? (
        <p>No token withdrawal requests found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Token</th>
              <th>Amount</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {tokenWithdrawalEvents.map((event, index) => (
              <tr key={index}>
                <td>{event.token}</td>
                <td>{event.amount}</td>
                <td>{event.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TokenWithdrawalRequests;
