import React from "react";
import {
  Tabs,
  TabList,
  Text,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Image,
  Center,
  VStack,
} from "@chakra-ui/react";
import CreateWallet from "./CreateWallet"; // Import your CreateWallet component
import DeployedWallets from "./DeployedWallets"; // Import your DeployedWallets component
import DataRuntime from "../../api/DataRuntime";

const WalletTabs = () => {
  return (
    <Tabs size="sm" variant="enclosed" mt={1}>
      <TabList p={0}>
        <Tab>Home</Tab>
        <Tab>Status</Tab>
        <Tab>Create</Tab>
        <Tab>Load</Tab>
      </TabList>

      <TabPanels>
        <TabPanel p={1}>
        <Center>
<VStack>
            
<Image w="200px" src="./positionbalancer.png" />
          <Text overflow="auto" noOfLines={4} maxWidth={300}>
            Blindfold is a self-sustaining, automated options exchange and
            account management server and SmartContract deployed on the Avalanche blockchain.
          </Text>
          
</VStack>
        </Center>
        </TabPanel>

        <TabPanel p={0}>
          <Box p={1}>
            <Text>Server Status:</Text>
            <DataRuntime />
            </Box>
          
        </TabPanel>
        <TabPanel p={0}>
          <CreateWallet /> 
        </TabPanel>
        <TabPanel p={0}>
          <DeployedWallets /> 
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default WalletTabs;
