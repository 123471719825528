import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import TenetWallet from "../../../contracts/TenetWallet.json";  // Your contract's ABI and address

const WithdrawalRequests = () => {
  const [withdrawalEvents, setWithdrawalEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    const provider = new ethers.providers.JsonRpcProvider(
        "https://api.avax.network/ext/bc/C/rpc"
      );

    const fetchWithdrawalEvents = async () => {
      setLoading(true);
      try {
        const contract = new ethers.Contract(TenetWallet.address, TenetWallet.abi, provider);

        // Filter to get all past WithdrawalRequested events
        const filter = contract.filters.WithdrawalRequested();
        const events = await contract.queryFilter(filter);

        // Format the events and store in state
        const formattedEvents = events.map((event) => {
          return {
            account: event.args[0],  // Account that requested the withdrawal
            amount: ethers.utils.formatEther(event.args[1]),  // Amount in Ether (assuming the amount is in wei)
            timestamp: new Date(event.args[2] * 1000).toLocaleString(),  // Convert timestamp from seconds to date
          };
        });

        setWithdrawalEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWithdrawalEvents();
  }, []);  // Empty dependency array to run only once

  return (
    <div>
      <h2>Withdrawal Requests</h2>
      {loading ? (
        <p>Loading withdrawal events...</p>
      ) : withdrawalEvents.length === 0 ? (
        <p>No withdrawal events found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Account</th>
              <th>Amount (ETH)</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {withdrawalEvents.map((event, index) => (
              <tr key={index}>
                <td>{event.account}</td>
                <td>{event.amount}</td>
                <td>{event.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WithdrawalRequests;
