import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import JakobWallet from '../../contracts/JakobWallet.json';
import { Box, Button, Heading, Input, Text, VStack, Select } from '@chakra-ui/react';
import { AppContext } from '../../AppContext';

const TenetPluginManager = () => {
  const { rpcUrl, account, contractAddress } = useContext(AppContext);

  const [isApproved, setIsApproved] = useState(false);
  const [status, setStatus] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const [selectedToken, setSelectedToken] = useState('USDC_ADDRESS'); // Default token selection
  const [tokenAddressMap, setTokenAddressMap] = useState({
    USDC_ADDRESS: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // Replace with actual token addresses
    USDCE_ADDRESS: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    WAVAX_ADDRESS: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    AVAX_ADDRESS: '0x0000000000000000000000000000000000000000', // Native AVAX
    WETH_ADDRESS: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    WBTC_ADDRESS: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    BTC_ADDRESS: '0x152b9d0FdC40C096757F570A51E494bd4b943E50'
  });

  const contractABI = JakobWallet.abi;

  useEffect(() => {
    checkPluginApproval();
  }, []);

  const checkPluginApproval = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        contractAddress,
        JakobWallet.abi,
        provider
      );
      const positionRouterAddress = '0xffF6D276Bc37c61A23f06410Dce4A400f66420f8'; // Example address

      const isApproved = await contract.isPluginApproved(contractAddress, positionRouterAddress);
      console.log(`Plugin Approved`, isApproved);
      setIsApproved(isApproved);
    } catch (error) {
      console.error('Error checking Position Router plugin approval:', error);
    }
  };

  const handleApprovePlugin = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const tx = await contract.approvePositionRouter();
      setStatus('Approving Position Router as plugin...');
      await tx.wait();
      setStatus('Plugin Position Router approved successfully!');
    } catch (error) {
      console.error('Error approving plugin:', error);
      setStatus('Error approving plugin.');
    }
  };

  const handleApproveTokenSpending = async () => {
    if (!tokenAmount || isNaN(tokenAmount) || Number(tokenAmount) <= 0) {
      setStatus('Please enter a valid amount.');
      return;
    }

    try {
      const amountInWei = ethers.utils.parseUnits(tokenAmount, 6); // Assuming tokens have 6 decimals (adjust as needed)
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const tokenAddress = tokenAddressMap[selectedToken]; // Get the selected token address

      const tx = await contract.approveTokenSpending(tokenAddress, amountInWei);
      setStatus(`Approving ${selectedToken} spending...`);
      await tx.wait();
      setStatus(`${selectedToken} spending approved successfully!`);
    } catch (error) {
      console.error(`Error approving ${selectedToken} spending:`, error);
      setStatus(`Error approving ${selectedToken} spending.`);
    }
  };

  return (
    <Box margin="0 auto">
      

      <Box p={4} mb={2}>
      <Heading as="h3" size="md" marginTop="2px">
        Approve Token Spending
      </Heading>
      <Select
        value={selectedToken}
        onChange={(e) => setSelectedToken(e.target.value)}
        backgroundColor="white"
        marginBottom="10px"
      >
        {Object.keys(tokenAddressMap).map((tokenKey) => (
          <option key={tokenKey} value={tokenKey}>
            {tokenKey.replace('_ADDRESS', '')}
          </option>
        ))}
      </Select>
      <Input
        type="text"
        value={tokenAmount}
        onChange={(e) => setTokenAmount(e.target.value)}
        placeholder="Amount"
        backgroundColor="white"
      />
      <Button colorScheme="blue" width="100%" onClick={handleApproveTokenSpending}>
        Approve {selectedToken.replace('_ADDRESS', '')} Spending
      </Button>
      </Box>

      <Box p={4} bg='ghostwhite'>
        <Heading as="h3" size="md" marginTop="2px">
          Approve Exchange Router
        </Heading>

        <p>Plugin Approved: {isApproved ? 'Yes' : 'No'}</p>

        {!isApproved && (
          <>
            <Button colorScheme="teal" width="100%" onClick={handleApprovePlugin}>
              Approve Plugin
            </Button>
          </>
        )}
      </Box>
      {status && (
        <Text color={status.includes('Error') ? 'red.500' : 'green.500'} marginTop="20px">
          {status}
        </Text>
      )}
    </Box>
  );
};

export default TenetPluginManager;
