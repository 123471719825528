import { AppContext } from "./AppContext";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ethers } from "ethers";
import {
  Box,
  Center,
  TabPanel,
  TabPanels,
  Tabs,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  Tab,
  TabList,
  GridItem,
  Grid,
  Wrap,
  WrapItem,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import {
  FiHome,
  FiBarChart2,
  FiUser,
  FiDatabase,
  FiSettings,
  FiHelpCircle,
  FiBook,
} from "react-icons/fi";
import VaultPriceFeed from "./contracts/VaultPriceFeed.json";
import FooterMain from "./components/FooterMain";
import JakobWallet from "./contracts/JakobWallet.json";
import TenetBox from "./components/wallet/TenetBox";
import GmxPositions from "./components/gmx/GmxPositions";
import TokenValue from "./components/wallet/TokenValue";
import WalletTabs from "./components/wallet/WalletTabs";
import DataTabs from "./components/DataTabs";
import { formatAddress } from "./utils/formatMetamask";
import LogoutButton from "./components/wallet/LogoutButton";
import CopyToClipboardButton from "./utils/CopyToClipboardButton";
import ActiveContractInfo from "./components/ActiveContractInfo";
import DataTokenPriceWavax from "./api/DataTokenPriceWavax";
import DataWavaxBollinger from "./api/DataWavaxBollinger";
import WavaxRsiIndicator from "./api/WavaxRsiIndicator";
import DataTokenPriceWeth from "./api/DataTokenPriceWeth";
import IndicatorSelector from "./components/wallet/IndicatorSelector";
import BlindfoldInfo from "./components/documentation/BlindfoldInfo";
import ApplicationTopology from "./components/documentation/ApplicationTopology";
import BlindfoldWalletContract from "./components/documentation/BlindfoldWalletContract";
import ContractList from "./components/documentation/ContractList";
import WavaxSmaIndicatorBase from "./components/indicators/WavaxSmaIndicatorBase";
import WavaxKSTIndicatorBase from "./components/indicators/WavaxKnowForSureBase";
import DataWavaxBollingerBase from "./api/DataWavaxBollingerBase";
import WavaxRsiValueBase from "./components/indicators/WavaxRsiValueBase";
//import ResponsiveSignals from "./components/ResponsiveSignals";
import FetchBtcPriceBase from "./components/prices/FetchBtcPriceBase";
import FetchFGIBase from "./components/indicators/FetchFGIBase";
function App() {
  const tabOrientation = "vertical"; // Always vertical

  // RPC Connections

  const rpc43114 = "https://api.avax.network/ext/bc/C/rpc";
  const [rpcUrl, setRpcUrl] = useState(rpc43114);
  const [contractAddress, setContractAddress] = useState(JakobWallet.address);
  const walletABI = JakobWallet.abi;
  const [contractName, setContractName] = useState("");
  // State Variables
  const [tradeSettings, setTradeSettings] = useState(null);
  const [chainId, setChainId] = useState(43114);
  const [account, setAccount] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCollateral, setTotalCollateral] = useState(0);
  const [totalPositions, setTotalPositions] = useState(0);

  // indicators
  const [kstSignal, setKstSignal] = useState(null);
  const [smaSignal, setSmaSignal] = useState(null);

  const [bollingerSignal, setBollingerSignal] = useState(null); // State to store the signal (BUY/SELL)
  //const [bollingerPrice, setBollingerPrice] = useState(null); // State to store the latest price
  const [error, setError] = useState(null); // State to store any potential errors
  const [rsiInfo, setRsiInfo] = useState({ rsi: null, signal: "HOLD" });
  const [btcPrice, setBtcPrice] = useState(null);

  const [fgiSignal, setFgiSignal] = useState("");
  const [fgiValue, setFgiValue] = useState("");

  // Callback function to receive the FGI signal
  const handleFgiSignalChange = (signal) => {
    setFgiSignal(signal);
  };
  // Callback function to receive the FGI value
  const handleFgiValueChange = (signal) => {
    setFgiValue(signal);
  };
  // Callback function to update the BTC price
  const handleBtcPriceUpdate = (newBtcPrice) => {
    setBtcPrice(newBtcPrice);
  };
  // Handle signal and RSI changes from the child component
  const handleRsiSignalChange = ({ rsi, signal }) => {
    setRsiInfo({ rsi, signal });
  };
  // Handle signal change from the child component
  const handleBollingerSignalChange = (newBollingerSignal) => {
    setBollingerSignal(newBollingerSignal);
  };

  // Handle the latest price update from the child component
  const handleBollingerPriceUpdate = (newBollPrice) => {
    //setBollingerPrice(newBollPrice);
  };

  // Optionally, handle error updates
  const handleError = (errorMsg) => {
    setError(errorMsg);
  };

  const handleSmaSignalChange = (newSignal) => {
    setSmaSignal(newSignal);
    console.log("KST Signal Updated:", newSignal); // Optionally log or process the signal
  };
  const handleSignalChange = (newSignal) => {
    setKstSignal(newSignal);
    console.log("KST Signal Updated:", newSignal); // Optionally log or process the signal
  };

  const handleTotalCollateralChange = (collateral) => {
    setTotalCollateral(parseFloat(collateral) || 0);
  };

  const handleTotalPositionsChange = (totalPositions) => {
    setTotalPositions(parseFloat(totalPositions) || 0);
  };

  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const [currentPrice, setCurrentPrice] = useState("");
  const fetchPrice = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://api.avax.network/ext/bc/C/rpc"
      );
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider
      );
      const tx = await routerContract.getPriceV1(
        "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
        false,
        true
      );
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(tx);
      const price = priceInWei.div(scaleFactor);
      const adjusted = Number(price.toString()) / 1000;
      setCurrentPrice(adjusted);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch contract name
  const fetchContractName = async () => {
    if (!contractAddress) return; // Skip if no contractAddress
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        contractAddress,
        walletABI,
        provider
      );
      const name = await contract.ContractName();
      setContractName(name);
    } catch (error) {
      console.error("Error fetching contract info:", error);
    }
  };

  useEffect(() => {
    fetchPrice();
    if (contractAddress) {
      fetchContractName();
    }
  }, [contractAddress]);

  // Callback to handle the trade settings data
  const handleDataFetched = (settings) => {
    console.log("Trade settings fetched:", settings);
    setTradeSettings(settings); // Set the fetched data in the state
  };

  const [totalBalance, setTotalBalance] = useState(0);

  const updateTotalBalance = (balance) => {
    setTotalBalance(balance);
  };

  const [activeBalance, setActiveBalance] = useState(0);

  const handleActiveBalanceUpdate = (balance) => {
    setActiveBalance(balance);
  };

  return (
    <AppContext.Provider
      value={{
        account,
        setAccount,
        chainId,
        setChainId,
        accountBalance,
        setAccountBalance,
        walletABI,
        rpcUrl,
        rpc43114,
        provider,
        contractAddress,
        setContractAddress,
        contractName,
        setContractName,
      }}
    >
      <Router>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
          }}
        >
          <div style={{ padding: 0, flexDirection: "row" }}>
            <NavBar loading={isLoading} />
          </div>

          <Routes>
            <Route
              path="/"
              element={
                <Box
                  height="100%"
                  minHeight="86vh"
                  w="auto"
                  bg="#2c2a2b"
                  justify="center"
                >
                
                  
      {/**            <WavaxKSTIndicatorBase onSignalChange={handleSignalChange} />
                  <WavaxSmaIndicatorBase
                    onSignalChange={handleSmaSignalChange}
                  />
                  <DataWavaxBollingerBase
                    onBollingerSignalChange={handleBollingerSignalChange}
                    onBollingerPriceUpdate={handleBollingerPriceUpdate}
                    onError={handleError}
                  />
                  <WavaxRsiValueBase onSignalChange={handleRsiSignalChange} />
                  <FetchBtcPriceBase onBtcPriceUpdate={handleBtcPriceUpdate} />
                  <FetchFGIBase onFgiValue={handleFgiValueChange} onSignalChange={handleFgiSignalChange} />
       */}


                  <Center mt={4}>
                    <Tabs
                      isLazy
                      bg="white"
                      minWidth={{ base: "full", md: "500px" }} // Full width on mobile, fixed on desktop
                      minHeight="400px"
                      mb={4}
                      w='100%'
                      h="full"
                      display="flex"
                      flexDirection="row" // Keep flex direction row for vertical tabs
                      orientation={tabOrientation}
                    >
                      <TabList
                        width="55px" // Narrow width for icons only
                        bg="#f7f8fc"
                        shadow="lg"
                        borderWidth="2px"
                        //borderRadius="md"
                        p={0}
                        gap={4}
                      >
                        <TabItem icon={FiHome} label="Home" />
                        <TabItem icon={FiBarChart2} label="Positions" />
                        <TabItem icon={FiDatabase} label="Data" />
                        <TabItem icon={FiBook} label="Help" />
                      </TabList>

                      <TabPanels flex="1" h="100%" bg="white">
                        {/** Home tab / Loader */}
                        <TabPanel p={0}>
                          {/**Active account and logout button */}
                          {contractAddress && (
                            <>
                            <HStack justify="left" w="100%" p={2} mt={2} shadow="md" borderBottom='1px solid gray.100'>
                            {contractAddress && <ActiveContractInfo />}
                          </HStack>
                            </>
                          )}
                          {/**Create/load/status */}
                          <WalletTabs />
                          
                        </TabPanel>
                        {/** Positions */}
                        <TabPanel p={0}>
                          <HStack justify="left" w="100%" p={2} mt={2} shadow="md" borderBottom='1px solid gray.100'>
                            {contractAddress && <ActiveContractInfo />}
                          </HStack>
                          <Wrap
                            justify="center"
                            p={1}
                            mb={4}
                            w="100%"
                            //shadow="md"
                            //borderWidth="1px"
                            //borderRadius="md"
                          >
                            <WrapItem justify='center' w='100%'>
                              <TenetBox
                                totalCollateral={totalCollateral}
                                totalPositions={totalPositions}
                                handleDataFetched={handleDataFetched}
                                activeBalance={activeBalance}
                              />
                            </WrapItem>
                            
                          </Wrap>

                          {/** Positions mapping */}
                          <GmxPositions
                            onTotalCollateralChange={
                              handleTotalCollateralChange
                            }
                            onTotalPositionChange={handleTotalPositionsChange}
                            contractAddress={contractAddress}
                            currentPrice={currentPrice}
                            fetchPrice={fetchPrice}
                            tradeSettings={tradeSettings}
                            handleActiveBalanceUpdate={handleActiveBalanceUpdate}
                                
                                
                          />

                          {/** {/** AVAX/WETH Price Charts
                          <Text as="b">Prices</Text>
                          <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            spacing={4}
                            width="100%"
                          >
                            <Box bg="white" p={4} mt={{ base: 2, md: 4 }}>
                              <Text fontSize="md" fontWeight="bold" mb={2}>
                                WAVAX Price
                              </Text>
                              <DataTokenPriceWavax />
                            </Box>

                            <Box bg="white" p={4} mt={{ base: 2, md: 4 }}>
                              <Text fontSize="md" fontWeight="bold" mb={2}>
                                WETH Price
                              </Text>

                              <DataTokenPriceWeth />
                            </Box>
                          </SimpleGrid> */}
                          {/** Bollinger Bands / Indicator selector */}
                         {/** <Text as="b">Indicators</Text>
                          <Text>Know For Sure: {kstSignal}</Text>
                          <Text>SMA: {smaSignal}</Text>
                          <IndicatorSelector
                            handleSignalChange={handleSignalChange}
                            handleSmaSignalChange={handleSmaSignalChange}
                          /> */}
                        </TabPanel>

                        {/** Database */}
                        <TabPanel>
                          <DataTabs
                            currentPrice={currentPrice}
                            totalCollateral={totalCollateral}
                          />
                        </TabPanel>

                        {/** Documentation */}
                        <TabPanel>
                          <Text>Documentation</Text>

                          <BlindfoldInfo />
                          <ApplicationTopology />
                          <BlindfoldWalletContract />
                          <ContractList />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Center>
                </Box>
              }
            />
          </Routes>
          <FooterMain
            style={{ flexShrink: 0, position: "sticky", bottom: 0 }}
          />
        </div>
      </Router>
    </AppContext.Provider>
  );

  function TabItem({ icon: IconComponent, label }) {
    return (
      <Popover trigger="hover" placement="right">
        <PopoverTrigger>
          <Tab>
            <IconComponent size={24} />
          </Tab>
        </PopoverTrigger>
        <PopoverContent width="fit-content" boxShadow="lg" p={2}>
          <PopoverBody display="flex" alignItems="center">
            <IconComponent size={24} style={{ marginRight: "8px" }} />
            {label}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
}

export default App;
