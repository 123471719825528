import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import {
  Box,
  Center,
  HStack,
  Spinner,
  Text,
  VStack,
  Button,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { AppContext } from "../../AppContext";
import JakobFactory from "../../contracts/JakobFactory.json";
import JakobWallet from "../../contracts/JakobWallet.json";
import { formatAddress } from "../../utils/formatMetamask";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";
import WalletDetails from "./WalletDetails"; // Import the WalletDetails component

const DeployedWallets = () => {
  const [deployedWallets, setDeployedWallets] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const { rpcUrl, setContractAddress } = useContext(AppContext);
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const walletFactoryContract = new ethers.Contract(
    JakobFactory.address,
    JakobFactory.abi,
    provider
  );

  const USDC_E_ADDRESS = "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664";
  const AVAX_ADDRESS = "0x0000000000000000000000000000000000000000";
  const WAVAX_ADDRESS = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7";
  const BTC_ADDRESS = "0x152b9d0FdC40C096757F570A51E494bd4b943E50";
  const WETH_ADDRESS = "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB";

  const fetchNativeBalance = async (walletAddress) => {
    const balance = await provider.getBalance(walletAddress);
    return ethers.utils.formatUnits(balance, 18);
  };

  const fetchTokenBalance = async (tokenAddress, walletAddress) => {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ["function balanceOf(address owner) view returns (uint256)"],
      provider
    );
    const balance = await tokenContract.balanceOf(walletAddress);
    return ethers.utils.formatUnits(
      balance,
      tokenAddress === USDC_E_ADDRESS
        ? 6
        : tokenAddress === BTC_ADDRESS
        ? 8
        : 18
    );
  };

  const fetchOwnersFromWallet = async (walletAddress) => {
    const walletContract = new ethers.Contract(
      walletAddress,
      JakobWallet.abi,
      provider
    );
    const [owner1, owner2, owner3, paymaster] = await Promise.all([
      walletContract.owner1(),
      walletContract.owner2(),
      walletContract.owner3(),
      walletContract.paymaster(),
    ]);
    return { owner1, owner2, owner3, paymaster };
  };

  const fetchDeployedWallets = async () => {
    try {
      setLoading(true);
      const wallets = await walletFactoryContract.getDeployedWallets();

      const updatedWallets = await Promise.all(
        wallets.map(async (wallet) => {
          const nativeBalance = await fetchNativeBalance(wallet.walletAddress);
          const usdceBalance = await fetchTokenBalance(
            USDC_E_ADDRESS,
            wallet.walletAddress
          );
          const wavaxBalance = await fetchTokenBalance(
            WAVAX_ADDRESS,
            wallet.walletAddress
          );
          const btcBalance = await fetchTokenBalance(
            BTC_ADDRESS,
            wallet.walletAddress
          );
          const wethBalance = await fetchTokenBalance(
            WETH_ADDRESS,
            wallet.walletAddress
          );
          const owners = await fetchOwnersFromWallet(wallet.walletAddress);

          return {
            ...wallet,
            ...owners,
            nativeBalance,
            usdceBalance,
            wavaxBalance,
            btcBalance,
            wethBalance,
          };
        })
      );

      setDeployedWallets(updatedWallets);
    } catch (error) {
      console.error("Error fetching deployed wallets:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeployedWallets();
  }, []);

  // Toggle the visibility of details for a specific wallet
  const toggleDetails = (index) => {
    setDeployedWallets((prevWallets) =>
      prevWallets.map((wallet, i) =>
        i === index ? { ...wallet, showDetails: !wallet.showDetails } : wallet
      )
    );
  };

  return (
    <Box p={2}>
      <Text mb={2} fontWeight="bold">
        Accounts
      </Text>

      {loading ? (
        <Center>
          <VStack>
            <Spinner />
            <Text fontSize="sm">Loading Deployments</Text>
          </VStack>
        </Center>
      ) : deployedWallets.length > 0 ? (
        deployedWallets.map((wallet, index) => {
          return (
            <Box
              key={index}
              p={1}
              fontSize="13px"
              shadow="md"
              borderWidth="1px"
              //borderRadius="md"
              mb={2}
            >
              <HStack p={1} w="100%">
                <HStack w="100%" overflow="hidden">
                  <Text as="b" mr={4} overflow="hidden">
                    {wallet.contractName}
                  </Text>
                  <Text textAlign="right">
                    <a
                      href={`https://snowtrace.io/address/${wallet.walletAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatAddress(wallet.walletAddress)}
                    </a>
                  </Text>
                  <CopyToClipboardButton value={wallet.walletAddress} />
                  <Spacer />
                  <Text>{wallet.category}</Text>
                </HStack>

                {/* Toggle Details IconButton */}
                {/* WalletDetails component displayed conditionally */}
                {/* Toggle Details IconButton */}
                <IconButton
                  icon={
                    wallet.showDetails ? <ChevronUpIcon /> : <ChevronDownIcon />
                  }
                  aria-label="Toggle Details"
                  onClick={() => toggleDetails(index)}
                  //mb={2}
                  variant="outline"
                  size="xs"
                  colorScheme="blue"
                />
              </HStack>
              <Box>
                {/* IconButton to toggle details */}

                {/* Details section */}
                {wallet.showDetails && (
                  <VStack align="start" spacing={2}>
                    <HStack>
                      <Text>
                        <strong>Owner1:</strong>{" "}
                        <a
                          href={`https://snowtrace.io/address/${wallet.owner1}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.owner1)}
                        </a>
                      </Text>
                      <Spacer />
                      <CopyToClipboardButton value={wallet.owner1} />
                    </HStack>

                    <HStack>
                      <Text>
                        <strong>Owner2:</strong>{" "}
                        <a
                          href={`https://snowtrace.io/address/${wallet.owner2}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.owner2)}
                        </a>
                      </Text>
                      <Spacer />
                      <CopyToClipboardButton value={wallet.owner2} />
                    </HStack>

                    <HStack>
                      <Text>
                        <strong>Owner3:</strong>{" "}
                        <a
                          href={`https://snowtrace.io/address/${wallet.owner3}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.owner3)}
                        </a>
                      </Text>
                      <Spacer />
                      <CopyToClipboardButton value={wallet.owner3} />
                    </HStack>

                    <HStack>
                      <Text mr={1}>
                        <strong>Spender:</strong>{" "}
                        <a
                          href={`https://snowtrace.io/address/${wallet.paymaster}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.paymaster)}
                        </a>
                      </Text>

                      <CopyToClipboardButton value={wallet.paymaster} />
                    </HStack>
                    {parseFloat(wallet.nativeBalance) > 0 && (
                      <Text>
                        <strong>AVAX Balance:</strong>{" "}
                        {parseFloat(wallet.nativeBalance).toFixed(2)} AVAX
                      </Text>
                    )}
                    {parseFloat(wallet.usdceBalance) > 0 && (
                      <Text>
                        <strong>USDC.e Balance:</strong>{" "}
                        {parseFloat(wallet.usdceBalance).toFixed(2)} USDC.e
                      </Text>
                    )}
                    {parseFloat(wallet.wavaxBalance) > 0 && (
                      <Text>
                        <strong>WAVAX Balance:</strong>{" "}
                        {parseFloat(wallet.wavaxBalance).toFixed(2)} WAVAX
                      </Text>
                    )}
                    {parseFloat(wallet.btcBalance) > 0 && (
                      <Text>
                        <strong>BTC Balance:</strong>{" "}
                        {parseFloat(wallet.btcBalance).toFixed(2)} BTC
                      </Text>
                    )}
                    {parseFloat(wallet.wethBalance) > 0 && (
                      <Text>
                        <strong>WETH Balance:</strong>{" "}
                        {parseFloat(wallet.wethBalance).toFixed(2)} WETH
                      </Text>
                    )}
                  </VStack>
                )}
              </Box>
              {/* Button to select this wallet */}
              <Button
                colorScheme="blue"
                size="xs"
                borderRadius={0}
                w="100%"
                onClick={() => setContractAddress(wallet.walletAddress)}
              >
                Select Wallet
              </Button>
            </Box>
          );
        })
      ) : (
        <Text>No wallets deployed yet.</Text>
      )}
    </Box>
  );
};

export default DeployedWallets;
