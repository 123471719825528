import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import TenetWallet from "../../../contracts/TenetWallet.json";  // Your contract's ABI and address

const ApprovalGranted = () => {
  const [approvalEvents, setApprovalEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://api.avax.network/ext/bc/C/rpc"  // Replace with your actual RPC URL
    );

    const fetchApprovalEvents = async () => {
      setLoading(true);
      try {
        const contract = new ethers.Contract(TenetWallet.address, TenetWallet.abi, provider);

        // Filter to get all past ApprovalGranted events
        const filter = contract.filters.ApprovalGranted();
        const events = await contract.queryFilter(filter, -1000, "latest");

        // Format the events and store in state
        const formattedEvents = events.map((event) => {
          return {
            owner: event.args[0],  // Address of the owner
            approved: event.args[1],  // Boolean approval status
            timestamp: new Date(event.blockNumber * 1000).toLocaleString(),  // Timestamp based on block number
          };
        });

        setApprovalEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching approval events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchApprovalEvents();
  }, []);  // Empty dependency array to run only once

  return (
    <div>
      <h2>Approval Granted Events</h2>
      {loading ? (
        <p>Loading approval events...</p>
      ) : approvalEvents.length === 0 ? (
        <p>No approval events found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Owner</th>
              <th>Approved</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {approvalEvents.map((event, index) => (
              <tr key={index}>
                <td>{event.owner}</td>
                <td>{event.approved ? "Yes" : "No"}</td>
                <td>{event.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ApprovalGranted;
