import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    HStack,
    Image,
    Text,
    Box,
    Center,
    Input,
    VStack,
    Heading,
    Select,
    Button,
  } from '@chakra-ui/react';
import { AppContext } from '../../AppContext';
import { useContext, useState } from 'react';
import JakobWallet from "../../contracts/JakobWallet.json";
import { ethers } from "ethers";
import { formatAddress } from "../../utils/formatMetamask";
import CopyToClipboardButton from "./../../utils/CopyToClipboardButton";

  const WithdrawalModal = ({ isOpen, onClose }) => {
    const [pendingWithdrawal, setPendingWithdrawal] = useState(null);
  const [pendingUsdcWithdrawal, setPendingUsdcWithdrawal] = useState(null);
  const [pendingBtcWithdrawal, setPendingBtcWithdrawal] = useState(null);
  const [wethAddress, setWethAddress] = useState("");
  const [btcAddress, setBtcAddress] = useState("");
  const [usdcAddress, setUsdcAddress] = useState("");
  const [usdceAddress, setUsdceAddress] = useState("");
  const [pendingWethWithdrawal, setPendingWethWithdrawal] = useState(null);
  const [pendingWavaxWithdrawal, setPendingWavaxWithdrawal] = useState(null);
  const [approval, setApproval] = useState(false);
  const [owner1Approval, setOwner1Approval] = useState(false);
  const [owner2Approval, setOwner2Approval] = useState(false);
  const [nativeBalance, setNativeBalance] = useState(0);
  const [owner3Approval, setOwner3Approval] = useState(false);
  const [approvalTimestamp, setApprovalTimestamp] = useState(null);
  const [wavaxAddress, setWavaxAddress] = useState("");
  const [usdcBalance, setUsdcBalance] = useState(0);
  const [wavaxBalance, setWavaxBalance] = useState(0);
  const [approvalWindowStatus, setApprovalWindowStatus] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
    const { rpcUrl, account, contractAddress } = useContext(AppContext);
    const erc20ABI = [
        "function balanceOf(address account) view returns (uint256)",
        "function approve(address spender, uint256 amount) external returns (bool)",
      ];
    
  // Fallback to JakobWallet.address if activeContractAddress is null
  const activeContractAddress = contractAddress || JakobWallet.address;

    const requestWithdrawal = async (withdrawAmount) => {
        if (
          !withdrawAmount ||
          isNaN(withdrawAmount) ||
          Number(withdrawAmount) <= 0
        ) {
          setStatus("Please enter a valid withdrawal amount.");
          return;
        }
    
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            activeContractAddress,
            JakobWallet.abi,
            signer
          );
    
          const parsedAmount = ethers.utils.parseEther(withdrawAmount.toString());
          const requestTx = await contract.requestWithdrawal(parsedAmount);
    
          setStatus("Requesting withdrawal...");
          await requestTx.wait();
    
          setStatus("Withdrawal request successful!");
          //fetchPendingAVAXWithdrawal(); // Refresh pending AVAX withdrawal
        } catch (error) {
          console.error("Error requesting withdrawal:", error);
          setStatus("Error requesting withdrawal.");
        }
      };
    
      
  const fetchContractBalance = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );
      const balance = await contract.getBalance();
      const formattedBalance = ethers.utils.formatEther(balance);
      setNativeBalance(Number(formattedBalance).toFixed(3));
    } catch (error) {
      console.error("Error fetching contract balance:", error);
    }
  };


  const fetchBalances = async () => {
    if (!usdcAddress || !wavaxAddress || !btcAddress || !wethAddress) return;
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const usdcContract = new ethers.Contract(usdcAddress, erc20ABI, provider);
      const btcContract = new ethers.Contract(btcAddress, erc20ABI, provider);
      const wavaxContract = new ethers.Contract(
        wavaxAddress,
        erc20ABI,
        provider
      );

      const [usdcBalance, wavaxBalance] = await Promise.all([
        usdcContract.balanceOf(activeContractAddress),
        wavaxContract.balanceOf(activeContractAddress),
      ]);

      setUsdcBalance(ethers.utils.formatUnits(usdcBalance, 6)); // USDC has 6 decimals
      setWavaxBalance(ethers.utils.formatEther(wavaxBalance)); // WAVAX has 18 decimals
    } catch (error) {
      console.error("Error fetching token balances:", error);
    }
  };

      const executeWithdrawal = async () => {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            activeContractAddress,
            JakobWallet.abi,
            signer
          );
    
          const executeTx = await contract.executeWithdrawal();
    
          setStatus("Executing withdrawal...");
          await executeTx.wait();
    
          setStatus("Withdrawal executed successfully!");
          fetchBalances();
          fetchContractBalance();
          //fetchPendingAVAXWithdrawal(); // Refresh pending AVAX withdrawal
        } catch (error) {
          console.error("Error executing withdrawal:", error);
          setStatus("Error executing withdrawal.");
        }
      };


      const executeTokenWithdrawal = async (tokenAddress) => {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            activeContractAddress,
            JakobWallet.abi,
            signer
          );
    
          const executeTx = await contract.executeTokenWithdrawal(tokenAddress);
    
          setStatus("Executing token withdrawal...");
          const receipt = await executeTx.wait();
    
          setStatus(
            `Token withdrawal executed successfully! Transaction Hash: ${receipt.transactionHash}`
          );
    
          fetchBalances(); // Refresh balances
          //fetchPendingUSDCWithdrawal(); // Refresh pending USDC withdrawal (you can add similar functions for BTC or WAVAX)
        } catch (error) {
          console.error("Error executing token withdrawal:", error);
    
          // Display a user-friendly error message
          const errorMessage =
            error.reason || error.message || "Error executing token withdrawal.";
          setStatus(errorMessage);
        }
      };

      const fetchTokenAddresses = async () => {
        try {
          const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
          const contract = new ethers.Contract(
            activeContractAddress,
            JakobWallet.abi,
            provider
          );
    
          const [usdcAddr, usdceAddr, wavaxAddr, btcAddr, wethAddr] = await Promise.all([
            contract.USDC_ADDRESS(),
            contract.USDCE_ADDRESS(),
            contract.WAVAX_ADDRESS(),
            contract.BTC_ADDRESS(),
            contract.WETH_ADDRESS(),
          ]);
    
          setUsdcAddress(usdcAddr);
          setUsdceAddress(usdceAddr);
          setWavaxAddress(wavaxAddr);
          setWethAddress(wethAddr);
          setBtcAddress(btcAddr);
        } catch (error) {
          console.error("Error fetching token addresses:", error);
        }
      };
    
      const requestTokenWithdrawal = async (tokenAddress, withdrawAmount) => {
        if (
          !withdrawAmount ||
          isNaN(withdrawAmount) ||
          Number(withdrawAmount) <= 0
        ) {
          setStatus("Please enter a valid withdrawal amount.");
          return;
        }
    
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            activeContractAddress,
            JakobWallet.abi,
            signer
          );
    
          // Determine the number of decimals for the token (BTC uses 6, others may use 18 or 6 for USDC)
          const decimals =
            tokenAddress === usdcAddress || tokenAddress === btcAddress ? 6 : 18;
          const parsedAmount = ethers.utils.parseUnits(
            withdrawAmount.toString(),
            decimals
          );
    
          const requestTx = await contract.requestTokenWithdrawal(
            tokenAddress,
            parsedAmount
          );
    
          setStatus("Requesting token withdrawal...");
    
          const receipt = await requestTx.wait();
    
          setStatus(
            "Token withdrawal request successful!",
            receipt.transactionHash
          );
          //fetchPendingUSDCWithdrawal(); // Refresh pending USDC withdrawal (you can add similar functions for BTC or WAVAX)
        } catch (error) {
          console.error("Error requesting token withdrawal:", error);
          setStatus("Error requesting token withdrawal.");
        }
      };
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack mb={-1}>
              <Image width={5} src={"./avax-logo.png"} alt="AVAX logo" />
              <Text>Withdraw</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <>
                    <Box
                      w="100%"
                      p={2}
                      bg="white"
                      mb={2}
                      //border="1px solid ThreeDFace"
                    >
                      <Heading as="h3" size="md" mb={4} textAlign="center">
                        Withdraw Tokens
                      </Heading>
                      <VStack spacing={4}>
                        <Select
                          bg="white"
                          placeholder="Select Token"
                          onChange={(e) => setSelectedToken(e.target.value)}
                        >
                          <option value={usdcAddress}>USDC</option>
                          <option value={usdceAddress}>USDC.e</option>
                          <option value={wavaxAddress}>WAVAX</option>
                          <option value={btcAddress}>BTC</option>
                          <option value={wethAddress}>WETH</option>
                        </Select>
                        <Input
                          bg="white"
                          type="text"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="Amount"
                        />
                        <Button
                          colorScheme="blue"
                          w="100%"
                          onClick={() => {
                            if (selectedToken) {
                              requestTokenWithdrawal(selectedToken, amount);
                            } else {
                              alert("Please select a token.");
                            }
                          }}
                        >
                          Request Withdrawal
                        </Button>
                        <Button
                          colorScheme="blue"
                          w="100%"
                          onClick={() => {
                            if (selectedToken) {
                              executeTokenWithdrawal(selectedToken);
                            } else {
                              alert("Please select a token.");
                            }
                          }}
                        >
                          Execute Withdrawal
                        </Button>
                      </VStack>
                    </Box>
                    <Box
                      w="100%"
                      p={2}
                      bg="ghostwhite"
                      border="1px solid ThreeDFace"
                    >
                      <Heading as="h3" size="md" mb={4} textAlign="center">
                        Withdraw AVAX
                      </Heading>
                      <VStack spacing={4}>
                        <Input
                          bg="white"
                          type="text"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="Amount"
                        />
                        <Button
                          colorScheme="blue"
                          w="100%"
                          onClick={() => requestWithdrawal(amount)}
                        >
                          Request Withdrawal
                        </Button>
                        <Button
                          colorScheme="blue"
                          w="100%"
                          onClick={executeWithdrawal}
                        >
                          Execute Withdrawal
                        </Button>
                      </VStack>
                    </Box>

                    <Center>
                      <Box p={2} bg="white" w="100%" textAlign="center">
                        {status && (
                          <Text
                            textAlign="center"
                            w="100%"
                            noOfLines={3}
                            mt={4}
                            color="red.500"
                          >
                            {status}
                          </Text>
                        )}
                      </Box>
                    </Center>
                  </>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  
  export default WithdrawalModal;
  